
import './bottom.scss'
import { useState } from 'react'

type propsType = {
    setPropsCurrentIndex:Function,  //修改首页选中下标
}
const TabBar:React.FunctionComponent<propsType>  = (props) => {
    const [list] = useState([
        {name:"首页"},
        {name:"关于我们"},
        {name:"我的产品"},
        {name:"招贤纳士"},
        {name:"联系我们"},
    ])

    return(
        <div className="BottomTabBarBox">
            <div className="content">
                <div className="leftBox">
                    <div className="listBox">
                        {
                            list.map((item,index)=>{
                                return(
                                    <div key={index} onClick={()=>props.setPropsCurrentIndex(index)}>
                                        <p className="tabBarText">{item.name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="addressBox">
                        <p className="addressText">地址：北京市大兴区亦庄镇荣华南路2号院大族广场T6号楼2202</p>
                        <p className="addressText">©2018 北京四翼鸟科技有限公司版权所有 <a className="beiAnHao" onClick={()=>window.open("https://beian.miit.gov.cn")}>京ICP备17058689号-2</a> </p>
                    </div>
                </div>
                <div className="rightBox">
                    <img className="iconImg" src={require('./../assets/icon/logo_white.png').default} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default TabBar
